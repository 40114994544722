import React, { useState, Fragment } from "react";
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import SearchResultPage from '../../components/SearchResult/SearchResult'
const SearchResult =()=> {
  const [state,setState] = React.useState({
    showMenu:false
})

const handlerClick = () => {

   
    setState({...state,showMenu:!state.showMenu})
   
    var root = document.getElementsByTagName('html')[0];
    if (state.showMenu == true) {
        document.body.className = ''; 
        root.className = ''; 
    } else {
        document.body.classList.add("overflow-hidden");   
        root.classList.add("overflow-hidden");  
    }

}
const [showSearch, setShowSearch] = useState(false);
const handlerSearchClick = () => { 
    setShowSearch(!showSearch)
    if (showSearch == true) {
        document.body.className = '';
    } else {
        document.body.classList.add("overflow-hidden");
    }
}

return(
        <>
 
          <div className={state.showMenu ? "wraapper header-style open-search-block" : "wraapper header-style"}>
          <Header showMenu={state.showMenu } handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} />
        <SearchResultPage/>
        <Footer />
        </div> 
        </>
    )
}

export default SearchResult;